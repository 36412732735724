import React, { useEffect } from 'react'

import Nav from 'components/common/Nav'
import { MEMBER } from 'constants/userStatus'

import starsEyes from 'images/emoji/starsEyes.png'
import { useLocation, useNavigate } from 'react-router-dom'
import { verifyEmail } from '../../utils/firebase/Auth'
import { getAuth } from 'firebase/auth'

type LocationState = {
  email: string
}

const EmailVerification = () => {
  const { email } = (useLocation().state as LocationState) || ''
  const navigate = useNavigate()

  const resendMail = () => {
    verifyEmail()
  }

  useEffect(() => {
    const auth = getAuth().currentUser
    auth?.emailVerified && navigate('/lounge')
    !email && navigate('/sign', { state: { isSignIn: false } })
    verifyEmail()
  }, [])

  return (
    <div className="w-full m-0 p-0 overflow-hidden bg-bgBlack">
      <Nav userStatus={MEMBER} />
      <div className="text-center h-screen pt-36">
        <div className="mb-36">
          <img
            src={starsEyes}
            alt="stars-eyes"
            className="w-20 h-auto mx-auto"
          />
          <p className="text-2xl font-bold text-s2condLime">
            이메일 인증을 보냈습니다
          </p>
          <p className="font-thin text-s2condLime">{email}을 확인하여 주세요</p>
        </div>
        <div className="flex justify-center">
          <button
            type="submit"
            className="border-1 border-s2condLime bg-bgBlack text-center text-s2condLime rounded-full h-12 w-96 mb-24 font-bold focus:outline-none hover:bg-s2condLime  hover:text-black"
            onClick={resendMail}
          >
            재전송 하기
          </button>
        </div>
      </div>
    </div>
  )
}

export default EmailVerification
