import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Emoji } from 'emoji-mart'
import { auth } from 'fbase'
import { dbUserContents } from 'types'
import {
  jobVacanciesConv,
  participationMethodConv,
  setBgColor,
  setBorderColor,
  setTextColor,
} from 'utils/Jobs'

type Props = {
  user: dbUserContents
}
const UserTable = ({ user }: Props) => {
  const navigate = useNavigate()
  const [isDaily, setIsDaily] = useState(false)
  const { dailyMe, s2condMe, uid } = user
  const selectedMe = isDaily ? dailyMe : s2condMe

  const moveToProfile = () => {
    if (auth.currentUser?.uid === uid) return navigate('/mypage')
    navigate(`/profile/${user.uid}`, { state: { dailyMe, s2condMe } })
  }

  return (
    <div className="flex m-5">
      {/* sidebar */}
      <div>
        <button
          className={`flex justify-center items-center p-3 border-1 rounded-l-lg ${setBorderColor(
            s2condMe.jobVacancies as string
          )}  ${
            !isDaily
              ? setBgColor(s2condMe.jobVacancies as string)
              : 'bg-bgBlack'
          }`}
          onClick={() => setIsDaily(false)}
        >
          <Emoji emoji="sunglasses" set="apple" size={26} />
        </button>
        <button
          className={`flex justify-center items-center p-3 border-1 rounded-l-lg 
        ${setBorderColor(dailyMe.jobVacancies as string)} ${
            isDaily ? setBgColor(dailyMe.jobVacancies as string) : 'bg-bgBlack'
          }`}
          onClick={() => setIsDaily(true)}
        >
          <Emoji emoji="sunglasses" set="apple" size={26} />
        </button>
      </div>
      {/* contents */}
      <div
        onClick={moveToProfile}
        className={`border-4 p-2 w-72 h-80 ${setBorderColor(
          selectedMe.jobVacancies as string
        )}`}
      >
        <div className="flex -mb-5">
          <div className="flex justify-center items-center relative w-28 h-28 bg-black rounded-full top-min8">
            <Emoji emoji="sunglasses" set="apple" size={28} />
          </div>
          <div className="ml-2 mt-1">
            <div
              className={`inline-block border-1 mx-auto p-1 mb-1 text-xs rounded-full 
          ${setTextColor(selectedMe.jobVacancies)} 
          ${setBorderColor(selectedMe.jobVacancies)}`}
            >
              @{jobVacanciesConv(selectedMe.jobVacancies)}
            </div>
            <br />
            <div
              className={`inline-block border-1  mx-auto p-1 text-xs rounded-full 
          ${setTextColor(selectedMe.jobVacancies)} 
          ${setBorderColor(selectedMe.jobVacancies)}`}
            >
              @{participationMethodConv(selectedMe.participationMethod)}
            </div>
          </div>
        </div>
        <div
          className={`text-center mb-4 font-bold 
      ${setTextColor(selectedMe.jobVacancies)}`}
        >
          {selectedMe.displayName} 요원
        </div>
        <div
          className="flex-nowrap text-white overflow-hidden p-1"
          style={{
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: 6,
          }}
        >
          {selectedMe.description}test area
        </div>
      </div>
    </div>
  )
}

export default UserTable
