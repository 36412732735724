import React from 'react'
import {
  jobVacanciesType,
  participationMethodType,
  profileContents,
} from 'types'
import Portal from './Portal'

type Props = {
  visibility: boolean
  setVisibility: React.Dispatch<React.SetStateAction<boolean>>
  dataType: string
  s2condMe: Partial<profileContents>
  setS2condMe: React.Dispatch<React.SetStateAction<Partial<profileContents>>>
  dailyMe: Partial<profileContents>
  setDailyMe: React.Dispatch<React.SetStateAction<Partial<profileContents>>>
  menuState: string
}
const StatusModal = ({
  visibility,
  setVisibility,
  dataType,
  s2condMe,
  setS2condMe,
  dailyMe,
  setDailyMe,
  menuState,
}: Props) => {
  const list =
    dataType === 'participation' ? participationMethodType : jobVacanciesType
  const changeStatus = (value: string) => {
    const data = menuState === 'S2COND' ? s2condMe : dailyMe
    const setData = menuState === 'S2COND' ? setS2condMe : setDailyMe
    if (dataType === 'participation') {
      setData({
        ...data,
        participationMethod: value as participationMethodType,
      })
    } else {
      setData({
        ...data,
        jobVacancies: value as jobVacanciesType,
      })
    }
    setVisibility((prev) => !prev)
  }
  return (
    <Portal elementId="modal">
      <div
        className={`fixed top-0 left-0 w-full h-screen z-30 bg-black opacity-75   ${
          visibility ? 'visible' : 'invisible'
        }`}
        onClick={() => setVisibility((prev) => !prev)}
      />
      <div
        className={`fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2
           border-2 border-white z-40 rounded-md bg-black 
           ${visibility ? 'visible' : 'invisible'}
           `}
      >
        {Object.values(list).map((value) => {
          return (
            <div className="text-center hover:bg-bgBlack" key={value}>
              <button
                className="py-8 px-24 text-white "
                onClick={() => changeStatus(value)}
              >
                {value}
              </button>
            </div>
          )
        })}
      </div>
    </Portal>
  )
}

export default StatusModal
