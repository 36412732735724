import {
  addDoc,
  collection,
  doc,
  DocumentData,
  getDoc,
  getDocs,
  onSnapshot,
  orderBy,
  query,
  setDoc,
  Timestamp,
  updateDoc,
  where,
} from 'firebase/firestore'
import { db } from 'fbase'
import {
  EmailUser,
  jobVacanciesType,
  participationMethodType,
  selectedInterestType,
  userFieldForUpdate,
} from 'types'
import { emptyProfile } from 'constants/emptyProfile'
import { User } from 'firebase/auth'

export const setUserDoc = async (
  contents: User | EmailUser,
  selectedInterest: selectedInterestType
) => {
  try {
    await setDoc(doc(db, 'users', contents.uid), {
      uid: contents.uid,
      email: contents.email,
      emailVerified: contents.emailVerified,
      photoUrl: contents.photoURL,
      displayName: contents.displayName,
      interests: selectedInterest,
      dailyMe: {
        jobVacancies: jobVacanciesType.unselected,
        participationMethod: participationMethodType.unselected,
        displayName: contents.displayName,
        description: '',
        picture: contents.photoURL,
        emoji: '',
        socialServices: [],
      },
      s2condMe: emptyProfile,
    })
  } catch (err) {
    console.log(err)
  }
}

export const isExistData = async (userId: string) => {
  const docRef = doc(db, 'users', userId)
  const docSnap = await getDoc(docRef)
  return docSnap.exists()
}

export const getUsers = async () => {
  try {
    const querySnapshot = await getDocs(collection(db, 'users'))
    if (!querySnapshot) return console.log('empty users')
    // querySnapshot.forEach((e) => console.log('e', e.data()))
    return querySnapshot
  } catch (err) {
    console.log(err)
  }
}

export const getUserById = async (uid: string) => {
  const docRef = doc(db, 'users', uid)
  try {
    const docSnapshot = await getDoc(docRef)
    if (!docSnapshot) return console.log('user is not exist')
    return docSnapshot
  } catch (err) {
    console.log(err)
  }
}

export const sendMessage = async ({
  id,
  text,
  from,
  to,
}: {
  [T: string]: string
}) => {
  await addDoc(collection(db, 'message', id, 'chat'), {
    text,
    from,
    to,
    createdAt: Timestamp.fromDate(new Date()),
  })
}

export const getMessages = (
  id: string,
  updateMessages: (msgs: DocumentData[]) => void
) => {
  const msgsRef = collection(db, 'message', id, 'chat')
  const q = query(msgsRef, orderBy('createdAt', 'asc'))
  onSnapshot(q, (querySnapshot) => {
    const msgs: DocumentData[] = []
    querySnapshot.forEach((doc) => {
      msgs.push(doc.data())
    })
    updateMessages(msgs)
  })
}

export const isUsernameUsable = async (username: string) => {
  const userRef = collection(db, 'users')
  const q = query(userRef, where('username', '==', username))
  const querySnapshot = await getDocs(q)
  let isUserExist = false
  querySnapshot.forEach((doc) => {
    if (doc.data()) return (isUserExist = true)
  })

  return !isUserExist
}

export const updateUserField = async (
  uid: string,
  data: userFieldForUpdate
) => {
  const userRef = doc(db, 'users', uid)
  try {
    await updateDoc(userRef, {
      ...data,
    })
  } catch (err) {
    console.log(err)
  }
}

export const getUsernameByUid = async (uid: string) => {
  const docRef = doc(db, 'users', uid)
  const docSnap = await getDoc(docRef)
  return docSnap.data()?.username || ''
}
