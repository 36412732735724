import facebook from 'images/logos/facebook.png'
import github from 'images/logos/github.png'
import google from 'images/logos/google.png'
import instagram from 'images/logos/instagram.png'
import linkedin from 'images/logos/linkedin.png'
import notion from 'images/logos/notion.png'
import youtube from 'images/logos/youtube.png'

const logoImg = {
  facebook,
  github,
  google,
  instagram,
  linkedin,
  notion,
  youtube,
}
export default logoImg
