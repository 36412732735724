import React, { ChangeEvent, useState } from 'react'
import { User } from 'firebase/auth'

type Props = {
  updateUser: () => Promise<void>
  user: User | null
}

const Setting = ({ updateUser, user }: Props) => {
  const [newPassword, setNewPassword] = useState('')
  const handleNewPassword = (e: ChangeEvent<{ value: string }>) => {
    setNewPassword(e.target.value)
  }
  return (
    <div className="w-full h-full text-white">
      <div className="w-full border-8 border-white overflow-visible p-4">
        <div className="block">
          <p className="text-borderGray mb-2">가입 정보</p>
        </div>
        <div className="flex">
          <p className="mr-8">{user?.displayName}</p>
          <div>
            <p className="w-1/2  border-b-4 border-borderGray">{user?.email}</p>
            <input
              type="text"
              value={newPassword}
              onChange={handleNewPassword}
            />
          </div>
        </div>
      </div>
      <div>
        <div
          className={`block w-full h-120 resize-none border-8 border-t-0 text-white p-8 bg-bgBlack outline-none border-white `}
        ></div>
        <button
          className={`p-2 border-8 float-right border-white`}
          onClick={updateUser}
        >
          <p className="border text-s2condPurple text-xs tracking-tighter leading-3 p-px Setting">
            OK
          </p>
        </button>
      </div>
    </div>
  )
}

export default Setting
