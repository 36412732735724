import { setBorderColor } from '../../utils/Jobs'
import { Emoji } from 'emoji-mart'
import React from 'react'
import { MENU_DAILY, MENU_S2COND, MENU_SETTING } from 'constants/index'
import type { jobVacanciesType, MyProfileMenuType } from 'types'
import { UserProfileMenuType } from 'types'

type SideBarType = {
  menuState: MyProfileMenuType
  handleMenuState: (state: MyProfileMenuType | UserProfileMenuType) => void
  s2condEmoji: string | undefined
  dailyEmoji: string | undefined
  jobVacancies: jobVacanciesType | undefined
  isMyProfile: boolean
}
const SideBar = ({
  menuState,
  handleMenuState,
  s2condEmoji,
  dailyEmoji,
  jobVacancies,
  isMyProfile,
}: SideBarType) => {
  return (
    <div className="w-40">
      <button
        className={`flex justify-start align-middle w-36 py-1 pl-2 border-8 border-r-0 focus:outline-none
              ${
                menuState === MENU_S2COND
                  ? setBorderColor(jobVacancies as string)
                  : 'border-textBlack'
              }
              `}
        onClick={() => handleMenuState(MENU_S2COND)}
      >
        <div>
          <Emoji emoji={`${s2condEmoji || 'coffee'}`} set="apple" size={16} />
        </div>
        <p className="text-borderGray font-bold ml-1">S2cond Me</p>
      </button>
      <button
        className={`flex justify-start align-middle w-36 py-1 pl-2 border-8 border-r-0 focus:outline-none
              ${
                menuState === MENU_DAILY
                  ? setBorderColor(jobVacancies as string)
                  : 'border-textBlack'
              }
              `}
        onClick={() => handleMenuState(MENU_DAILY)}
      >
        <div>
          <Emoji emoji={`${dailyEmoji || 'computer'}`} set="apple" size={16} />
        </div>
        <p className="text-borderGray ml-1">Daily Me</p>
      </button>
      {isMyProfile && (
        <button
          className={`flex justify-start align-middle w-36 py-1 pl-2 border-8 border-r-0 focus:outline-none
              ${
                menuState === MENU_SETTING ? 'border-white' : 'border-textBlack'
              }
              `}
          onClick={() => handleMenuState(MENU_SETTING)}
        >
          <div>
            <Emoji emoji="gear" set="apple" size={16} />
          </div>
          <p className="text-borderGray ml-1">Setting</p>
        </button>
      )}
    </div>
  )
}

export default SideBar
