import React, { useEffect, useState } from 'react'
import { Route, Routes, useLocation } from 'react-router-dom'
import { Navigate, Outlet } from 'react-router-dom'

import Landing from 'pages/Landing'
import Sign from 'pages/authPages/Sign'
import Lounge from 'pages/Lounge'
import MyPage from 'pages/Profile/MyPage'
import Profile from 'pages/Profile/Profile'
import InitUsername from 'pages/authPages/InitUsername'
import SignEmail from 'pages/authPages/SignEmail'
import EmailVerification from 'pages/authPages/EmailVerification'

import { getAuthStateWithLoad } from 'utils/firebase/Auth'
import { globalUserContents } from 'types'
import useUserState from 'store/userState'

type ProtectedPageProps = {
  username: string
  email: string
  emailVerified: boolean
}

const ProtectedPage = ({
  username,
  email,
  emailVerified,
}: ProtectedPageProps) => {
  // console.log(username, email, emailVerified)
  return username && email.length && emailVerified ? (
    <Outlet />
  ) : (
    <Navigate to="/" />
  )
}

const Router = () => {
  const location = useLocation()
  const [user, setUser] = useState<globalUserContents | null>(null)
  const [isAuthLoaded, setIsAuthLoaded] = useState(false)
  const [globalUser, setGlobalUser] = useUserState()

  const changeUserState = (user: globalUserContents) => {
    setUser(user)
    setGlobalUser(user)
    setIsAuthLoaded(true)
  }

  useEffect(() => {
    // console.log(globalUser.username)
    !user?.username && getAuthStateWithLoad(changeUserState)
    // console.log('user', user)
  }, [location.pathname])

  return (
    <Routes>
      <Route path="/" element={<Landing />} />
      <Route path="sign" element={<Sign />} />
      <Route path="email" element={<SignEmail />} />
      <Route path="profile/:id" element={<Profile />} />
      <Route path="emailverify" element={<EmailVerification />} />
      <Route path="init-username" element={<InitUsername />} />
      {isAuthLoaded && (
        <Route
          path="/"
          element={
            <ProtectedPage
              username={user?.username || ''}
              email={user?.email || ''}
              emailVerified={user?.emailVerified || false}
            />
          }
        >
          <Route path="mypage" element={<MyPage />} />
          <Route path="lounge" element={<Lounge />} />
        </Route>
      )}
    </Routes>
  )
}

export default Router
