import React from 'react'
import { interestsType } from 'constants/interests'

import InterestBtn from './InterestBtn'

type colorListType = {
  [key: string]: string
}
type selectedInterestType = {
  [category: string]: string[]
}
type landingProps = {
  data: interestsType
  selectedInterest: selectedInterestType
  setSelectedInterest: React.Dispatch<
    React.SetStateAction<selectedInterestType>
  >
}

const borderColorConv: colorListType = {
  life: 'border-white',
  identity: 'border-s2condPurple',
  arts: 'border-s2condPink',
  industry: 'border-s2condOrange',
  knowledge: 'border-s2condYellow',
  sports: 'border-s2condLime',
  languages: 'border-s2condMint',
}
const textColorConv: colorListType = {
  life: 'text-white',
  identity: 'text-s2condPurple',
  arts: 'text-s2condPink',
  industry: 'text-s2condOrange',
  knowledge: 'text-s2condYellow',
  sports: 'text-s2condLime',
  languages: 'text-s2condMint',
}

const InterestsList = ({
  data,
  selectedInterest,
  setSelectedInterest,
}: landingProps) => {
  const { [data.name.en.toLowerCase()]: borderColor } = borderColorConv
  const { [data.name.en.toLowerCase()]: textColor } = textColorConv
  const onInterestClick = (keyValue: string, category: string) => {
    const prevInterest = selectedInterest
    if (prevInterest[category]?.includes(keyValue)) {
      const index = prevInterest[category].indexOf(keyValue)
      prevInterest[category].splice(index, 1)
    } else {
      prevInterest[category].push(keyValue)
    }
    setSelectedInterest(prevInterest)
  }
  return (
    <>
      <div className="mb-8 flex items-start">
        <div className="grid grid-cols-6 w-full">
          <div
            className={` flex justify-items-start p-auto text-2xl font-bold ${textColor}`}
          >
            <p className={`h-12 border-b-8 ${borderColor}`}>{data.name.kr}</p>
          </div>
          <div className="col-span-5 ">
            {Object.entries(data).map(([key, value], i) => {
              return i > 0 ? (
                <div onClick={() => onInterestClick(key, data.name.en)} key={i}>
                  <InterestBtn
                    keyValue={key}
                    value={value}
                    color={borderColor}
                    key={key + value}
                  />
                </div>
              ) : null
            })}
          </div>
        </div>
      </div>
    </>
  )
}

export default InterestsList
