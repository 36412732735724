import React, { useCallback, useEffect, useState } from 'react'
import {
  dbUserContents,
  MyProfileMenuType,
  profileContents,
  userFieldForUpdate,
} from 'types'
import { getAuth } from 'firebase/auth'
import Nav from 'components/common/Nav'
import StatusModal from 'components/Modal/StatusModal'
import { getUserById, updateUserField } from 'utils/firebase/Db'
import SideBar from 'components/Profile/SideBar'
import {
  emptyProfile,
  MEMBER,
  MENU_DAILY,
  MENU_S2COND,
  MENU_SETTING,
} from 'constants/index'
import MyPageMeField from '../../components/Profile/MyPageMeField'
import Setting from '../../components/Profile/Setting'

const MyPage = () => {
  const user = getAuth().currentUser
  const [menuState, setMenuState] = useState<MyProfileMenuType>(MENU_S2COND)
  const [dailyMe, setDailyMe] = useState<Partial<profileContents>>(emptyProfile)
  const [s2condMe, setS2condMe] =
    useState<Partial<profileContents>>(emptyProfile)
  const [data, setData] = useState<Partial<profileContents>>(emptyProfile)
  const [onModal, setOnModal] = useState(false)
  const [modalData, setModalData] = useState('participation')

  const handleMe = (me: Partial<profileContents>) => {
    menuState === MENU_DAILY ? setDailyMe(me) : setS2condMe(me)
  }

  const handleChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target
    menuState === 'DAILY'
      ? setDailyMe((prevState) => ({
          ...prevState,
          [name]: value,
        }))
      : setS2condMe((prevState) => ({
          ...prevState,
          [name]: value,
        }))
  }
  const handleMenuState = (state: MyProfileMenuType) => {
    setMenuState(state)
  }
  const handleModal = (modalType: string) => {
    setModalData(modalType)
    setOnModal((prev) => !prev)
  }

  const updateUser = async () => {
    if (!user) return
    try {
      await updateUserField(user.uid, {
        s2condMe: s2condMe,
        dailyMe: dailyMe,
      } as userFieldForUpdate)
    } catch (err) {
      console.log('error', err)
    }
  }
  const getUserInfo = useCallback(async () => {
    if (!user) return
    const snapshot = await getUserById(user.uid)
    if (!snapshot) return
    const res = snapshot.data() as dbUserContents
    setDailyMe(res.dailyMe)
    setS2condMe(res.s2condMe)
  }, [user])

  useEffect(() => {
    const getData = async () => {
      await getUserInfo()
      setData(() => (menuState === 'DAILY' ? dailyMe : s2condMe))
    }
    getData()
  }, [user])
  useEffect(() => {
    setData(() => (menuState === 'DAILY' ? dailyMe : s2condMe))
  }, [menuState, user, s2condMe, dailyMe])

  return (
    <div className="h-screen w-full m-0 p-0 overflow-hidden bg-bgBlack">
      <Nav userStatus={MEMBER} />
      <div className="flex justify-center h-2/3 align-middle mt-24 mx-56">
        {/* sidebar */}
        <SideBar
          menuState={menuState}
          handleMenuState={handleMenuState}
          s2condEmoji={s2condMe.emoji}
          dailyEmoji={dailyMe.emoji}
          jobVacancies={data.jobVacancies}
          isMyProfile={true}
        />
        {/* profile contents */}
        {menuState === MENU_SETTING ? (
          <Setting updateUser={updateUser} user={user} />
        ) : (
          <MyPageMeField
            menuState={menuState}
            jobVacancies={data.jobVacancies}
            handleModal={handleModal}
            handleChange={handleChange}
            handleMe={handleMe}
            participationMethod={data.participationMethod}
            s2condMe={s2condMe}
            dailyMe={dailyMe}
            updateUser={updateUser}
          />
        )}
      </div>
      <StatusModal
        visibility={onModal}
        setVisibility={setOnModal}
        dataType={modalData}
        s2condMe={s2condMe}
        setS2condMe={setS2condMe}
        dailyMe={dailyMe}
        setDailyMe={setDailyMe}
        menuState={menuState}
      />
    </div>
  )
}

export default MyPage
