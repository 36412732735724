import { MENU_SETTING } from '../../constants'
import { setBorderColor, setTextColor } from '../../utils/Jobs'
import { Emoji } from 'emoji-mart'
import SocialBtnEdit from './SocialBtnEdit'
import React from 'react'
import {
  jobVacanciesType,
  MyProfileMenuType,
  participationMethodType,
  profileContents,
} from 'types'

type Props = {
  menuState: MyProfileMenuType
  jobVacancies: jobVacanciesType | undefined
  handleModal: (modalType: string) => void
  handleChange: (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => void
  handleMe: (me: Partial<profileContents>) => void
  participationMethod: participationMethodType | undefined
  s2condMe: Partial<profileContents>
  dailyMe: Partial<profileContents>
  updateUser: () => Promise<void>
}
const MyPageMeField = ({
  menuState,
  jobVacancies,
  handleModal,
  handleChange,
  handleMe,
  participationMethod,
  s2condMe,
  dailyMe,
  updateUser,
}: Props) => {
  return (
    <div className="w-full h-full">
      <div
        className={`w-full border-8 ${
          menuState === MENU_SETTING
            ? 'border-white'
            : setBorderColor(jobVacancies as string)
        } overflow-visible `}
      >
        <div className="flex justify-start align-middle overflow-visible z-10 -mb-6">
          {/* Profile Info */}
          <div className="ml-5 mr-12">
            <div className="flex justify-center align-middle z-20 relative rounded-full bg-black p-10 -top-4">
              <Emoji emoji="santa" set="apple" size={43} />
            </div>
            <div
              className={`flex justify-center align-middle z-20 relative ml-15 w-15 h-15 rounded-full bg-black p-4 border-2 left-4 -top-16
                  ${setBorderColor(jobVacancies as string)} `}
            >
              <Emoji emoji="santa" set="apple" size={24} />
            </div>
          </div>
          <div>
            <div className="flex mt-6">
              <button
                className={`text-center text-xs  border-1 py-1 px-2 mr-1 rounded-full
                    ${setTextColor(jobVacancies as string)} 
                    ${setBorderColor(jobVacancies as string)}
                    `}
                name="jobVacancies"
                onClick={() => handleModal('jobVacancies')}
              >
                @{jobVacancies}
              </button>
              <button
                className={`text-center text-xs border-1 py-1 px-2 rounded-full
                    ${setTextColor(jobVacancies as string)} 
                    ${setBorderColor(jobVacancies as string)}
                    `}
                name="participation"
                onClick={() => handleModal('participation')}
              >
                @{participationMethod}
              </button>
            </div>
            <input
              type="text"
              name="displayName"
              className={`font-bold my-3 bg-bgBlack outline-none
                  ${setTextColor(jobVacancies as string)} `}
              value={
                menuState === 'DAILY'
                  ? dailyMe.displayName
                  : s2condMe.displayName
              }
              onChange={(e) => handleChange(e)}
            />
            <div>
              <SocialBtnEdit
                s2condMe={s2condMe}
                dailyMe={dailyMe}
                menuState={menuState}
                handleMe={handleMe}
              />
            </div>
          </div>
        </div>
        <div>{/* Profile description */}</div>
      </div>
      {/* Text 적는 곳 */}
      <div>
        <textarea
          name="description"
          className={`block w-full h-120 resize-none border-8 border-t-0 text-white p-8 bg-bgBlack outline-none
              ${
                menuState === MENU_SETTING
                  ? 'border-white'
                  : setBorderColor(jobVacancies as string)
              } `}
          value={
            menuState === 'DAILY' ? dailyMe.description : s2condMe.description
          }
          onChange={(e) => handleChange(e)}
        />
        <button
          className={`p-2 border-8 float-right
              ${
                menuState === MENU_SETTING
                  ? 'border-white'
                  : setBorderColor(jobVacancies as string)
              }`}
          onClick={updateUser}
        >
          <p
            className={`border text-s2condPurple text-xs tracking-tighter leading-3 p-px
                ${
                  menuState === MENU_SETTING
                    ? 'border-white text-white'
                    : `${setBorderColor(jobVacancies as string)} ${setTextColor(
                        jobVacancies as string
                      )}`
                }
                `}
          >
            OK
          </p>
        </button>
      </div>
    </div>
  )
}

export default MyPageMeField
