import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Emoji } from 'emoji-mart'

import { getUserById } from 'utils/firebase/Db'
import { MEMBER } from 'constants/userStatus'
import Nav from 'components/common/Nav'
import {
  jobVacanciesConv,
  participationMethodConv,
  setBorderColor,
  setTextColor,
} from 'utils/Jobs'
import SocialBtn from 'components/Profile/SocialBtn'
import type {
  dbUserContents,
  profileContents,
  UserProfileMenuType,
} from 'types'
import { MyProfileMenuType } from 'types'
import {
  emptyProfile,
  MENU_DAILY,
  MENU_S2COND,
  MENU_SETTING,
} from 'constants/index'
import SideBar from 'components/Profile/SideBar'

const Profile = () => {
  const params = useParams()
  const [userInfo, setUserInfo] = useState<dbUserContents>()
  const [data, setData] = useState<Partial<profileContents>>(emptyProfile)
  // const [isChat, setIsChat] = useState(false)
  const [menuState, setMenuState] = useState<UserProfileMenuType>(MENU_S2COND)
  // const [owner] = useUserState()
  //
  // const openChat = () => {
  //   setIsChat((prev) => !prev)
  //   if (!isChat) return
  // }

  const handleMenuState = (state: MyProfileMenuType) => {
    if (state === MENU_SETTING) return
    setMenuState(state)
  }

  useEffect(() => {
    const getAsyncUserById = async () => {
      if (!params.id) return
      const snapshot = await getUserById(params.id)
      if (!snapshot) return
      const resData = snapshot.data() as dbUserContents
      setUserInfo(resData)
      setData(resData.s2condMe)
    }
    getAsyncUserById()
  }, [])
  useEffect(() => {
    if (!userInfo) return
    setData(menuState === MENU_DAILY ? userInfo.dailyMe : userInfo.s2condMe)
  }, [menuState])
  return userInfo ? (
    <div className="h-screen w-full m-0 p-0 overflow-hidden bg-bgBlack">
      <Nav userStatus={MEMBER} />
      <div className="flex justify-center h-2/3 align-middle mt-24 mx-56">
        {/* sidebar */}
        <SideBar
          menuState={menuState}
          handleMenuState={handleMenuState}
          s2condEmoji={userInfo.s2condMe.emoji}
          dailyEmoji={userInfo.dailyMe.emoji}
          jobVacancies={data.jobVacancies}
          isMyProfile={false}
        />
        {/* profile contents */}
        <div className="w-full h-full">
          <div
            className={`w-full border-8 ${setBorderColor(
              data.jobVacancies as string
            )} overflow-visible `}
          >
            <div className="flex justify-start align-middle overflow-visible z-10 -mb-6">
              {/* Profile Info */}
              <div className="ml-5 mr-12">
                <div className="flex justify-center align-middle z-20 relative rounded-full bg-black p-10 -top-4">
                  <Emoji emoji="santa" set="apple" size={43} />
                </div>
                <div
                  className={`flex justify-center align-middle z-20 relative ml-15 w-15 h-15 rounded-full bg-black p-4 border-2 left-4 -top-16
                  ${setBorderColor(data.jobVacancies as string)} `}
                >
                  <Emoji emoji="santa" set="apple" size={24} />
                </div>
              </div>
              <div>
                <div className="flex mt-6">
                  <div
                    className={`text-center text-xs border py-1 px-2 mr-1 rounded-full 
                    ${setBorderColor(
                      data.jobVacancies as string
                    )} ${setTextColor(data.jobVacancies as string)}`}
                  >
                    @{jobVacanciesConv(data.jobVacancies)}
                  </div>
                  <div
                    className={`text-center text-xs border py-1 px-2 rounded-full
                   ${setBorderColor(
                     data.jobVacancies as string
                   )} ${setTextColor(data.jobVacancies as string)}`}
                  >
                    @{participationMethodConv(data.participationMethod)}
                  </div>
                </div>
                <div
                  className={`${setTextColor(
                    data.jobVacancies as string
                  )}  font-bold my-3`}
                >
                  {data && data.displayName} 요원
                </div>
                <div>
                  <SocialBtn />
                </div>
              </div>
            </div>
            <div>{/* Profile description */}</div>
          </div>
          {/* Text 적는 곳 */}
          <div
            className={`w-full h-1/2 border-8 border-t-0 ${setBorderColor(
              data.jobVacancies as string
            )} text-white p-8`}
          >
            {data.description}
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div className="w-full m-0 p-0 overflow-hidden bg-bgBlack">
      <Nav userStatus={MEMBER} />
      <div>Loading ...</div>
    </div>
  )
}

export default Profile
