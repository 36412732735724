import React, { useState } from 'react'

import { Emoji } from 'emoji-mart'
import { interestType } from 'constants/interests'
import { emoji } from 'constants/emojiTagNames'

type Props = {
  keyValue: string
  value: interestType
  color: string
}

const InterestBtn = ({ keyValue, value, color }: Props) => {
  const [clicked, setClicked] = useState(false)
  const imgSrc = emoji[keyValue]
  const onInterestClick = () => {
    setClicked(!clicked)
  }

  return (
    <button
      onClick={onInterestClick}
      className={`float-left flex justify-center align-middle h-8 border rounded-full pt-1 px-4 mx-1 mb-2 focus:outline-none hover:${color} active:${color} ${
        clicked ? color : 'border-textBlack'
      } `}
    >
      <span className="mr-1">
        <Emoji emoji={imgSrc} size={13} />
      </span>
      <p className="text-white text-base font-thin">{value.kr}</p>
    </button>
  )
}

export default InterestBtn
