import Nav from 'components/common/Nav'
import { NONE } from 'constants/userStatus'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import useUserState from 'store/userState'
import { isUsernameUsable, updateUserField } from 'utils/firebase/Db'

const InitUsername = () => {
  const [username, setUsername] = useState('')
  const [isUsable, setIsUsable] = useState(true)
  const [isSearched, setIsSearched] = useState(false)
  const [userState, setUserState] = useUserState()
  const navigate = useNavigate()

  const minUsernameLength = 5
  const changeUsername = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value },
    } = e
    setUsername(value)
    username.length > minUsernameLength &&
      setIsUsable(await isUsernameUsable(value))
    setIsSearched(true)
  }
  const saveUsername = async () => {
    await updateUserField(userState.uid, { username })
    setUserState({ ...userState, username })
    navigate('/lounge')
  }
  return (
    <div className="w-full m-0 p-0 overflow-hidden bg-bgBlack">
      <Nav userStatus={NONE} />
      <div className="flex justify-center items-center flex-col text-center h-screen pt-36">
        <div className="mb-36 text-white">
          <p>유저 네임을 설정해주세요.</p>
        </div>
        <input
          type="text"
          placeholder="유저 이름"
          required
          value={username}
          onChange={changeUsername}
          className={`border border-textBlack bg-bgBlack text-white text-center font-bold rounded-full h-12 w-96 focus:outline-none focus:border-white mb-2 ${
            username && `border-white`
          } `}
        />
        {isSearched && username.length > minUsernameLength ? (
          <p className={`${isUsable ? 'text-s2condLime' : 'text-s2condPink'}`}>
            {isUsable ? '사용 가능합니다.' : '이미 존재하는 유저이름입니다.'}
          </p>
        ) : (
          <p>&nbsp;</p>
        )}
        {isUsable && username.length >= minUsernameLength ? (
          <button
            type="submit"
            className="mt-12 border-1 border-s2condLime bg-bgBlack text-center text-s2condLime rounded-full h-12 w-96 mb-24 font-bold focus:outline-none hover:bg-s2condLime  hover:text-black"
            onClick={saveUsername}
          >
            설정하기
          </button>
        ) : (
          <button
            type="submit"
            className="mt-12 border-1 border-borderGray bg-bgBlack text-center text-borderGray rounded-full h-12 w-96 mb-24 font-bold focus:outline-none"
            disabled
          >
            설정하기
          </button>
        )}
      </div>
    </div>
  )
}

export default InitUsername
