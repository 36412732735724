import React from 'react'
import { useNavigate } from 'react-router-dom'

import { UserStatus } from 'constants/userStatus'
import emojiAsset from 'images/emojiAsset'

type Props = {
  userStatus: UserStatus
}
const btnData = {
  None: {
    to: '/sign',
    state: { state: { isSignin: true } },
    emoji: null,
    content: '로그인',
  },
  LoggingIn: {
    to: '/',
    state: null,
    emoji: emojiAsset.millennials,
    content: '요원 신청',
  },
  SigningUp: {
    to: '/',
    state: null,
    emoji: null,
    content: '',
  },
  Member: {
    to: '/mypage',
    state: null,
    emoji: emojiAsset.millennials,
    content: 'My Page',
  },
}
const NavMainBtn = ({ userStatus }: Props) => {
  const navigate = useNavigate()
  const navigateLink = () => {
    btnData[userStatus].state
      ? navigate(btnData[userStatus].to, btnData[userStatus].state!)
      : navigate(btnData[userStatus].to)
  }

  return (
    <button
      className="flex md:mr-40 mr-56 font-monserrat text-sm font-bold text-white"
      onClick={navigateLink}
    >
      {btnData[userStatus].emoji && (
        <img
          src={btnData[userStatus].emoji!}
          alt="navbar_btn"
          className="mr-2"
        />
      )}
      {btnData[userStatus].content}
    </button>
  )
}

export default NavMainBtn
