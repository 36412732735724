import React, { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import Nav from 'components/common/Nav'
import { SIGNING_UP } from 'constants/userStatus'
import signupGun from 'images/emoji/signupGun.png'
import { emailSignIn, emailSingUp } from 'utils/firebase/Auth'
import { getUsernameByUid, isExistData, setUserDoc } from 'utils/firebase/Db'
import { checkValid } from 'utils/utils'
import useUserState from 'store/userState'
import { selectedInterestType } from 'types'

type LocationState = {
  isSignin: boolean
  selectedInterest: selectedInterestType
}

const SignEmail = () => {
  const { isSignin, selectedInterest } = useLocation().state as LocationState
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [validity, setValidity] = useState(false)
  const [passwordToggle, setPasswordToggle] = useState(true)
  const [_, setUserState] = useUserState()
  const navigate = useNavigate()

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {
      target: { name, value },
    } = e
    if (name === 'email') {
      setEmail(value)
    } else if (name === 'password') {
      setPassword(value)
      setValidity(checkValid(value))
    }
  }
  const onPassword = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault()
    setPasswordToggle((prev) => !prev)
  }
  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (!validity) return
    const signResult = isSignin
      ? await emailSignIn(email, password)
      : await emailSingUp(email, password)
    if (!signResult) return
    const dbUsername = await getUsernameByUid(signResult.uid)

    setUserState({
      uid: signResult.uid,
      email: signResult.email || '',
      emailVerified: signResult.emailVerified,
      phoneNumber: signResult.phoneNumber || '',
      photoURL: signResult.photoURL || '',
      displayName: signResult.displayName || '',
      username: dbUsername,
    })
    if (!(await isExistData(signResult.uid))) {
      setUserDoc(signResult, selectedInterest)
    }

    if (!signResult.emailVerified)
      navigate('/emailverify', { state: { email: signResult.email } })
    !dbUsername ? navigate('/init-username') : navigate('/lounge')
  }
  return (
    <div className="w-full m-0 p-0 overflow-hidden bg-bgBlack">
      <Nav userStatus={SIGNING_UP} />
      <div className="text-center text-white mt-36 ">
        <div className="mb-48">
          <img
            src={signupGun}
            alt="signup-gun"
            className="w-20 h-auto mx-auto"
          />
          <p className="text-2xl font-bold text-s2condLime">
            {isSignin ? '이메일 로그인' : '이메일 회원가입'}
          </p>
        </div>
        <div>
          <form onSubmit={onSubmit}>
            <input
              name="email"
              type="email"
              placeholder="이메일 주소"
              required
              value={email}
              onChange={onChange}
              className={`border border-textBlack bg-bgBlack text-center font-bold rounded-full h-12 w-96 focus:outline-none focus:border-white mb-2 ${
                email && `border-white`
              } `}
            />
            <br />
            <div className="mb-16">
              <input
                name="password"
                type={passwordToggle ? 'current-password' : 'text'}
                placeholder="비밀번호"
                required
                value={password}
                onChange={onChange}
                className={`border border-textBlack bg-bgBlack text-center font-bold rounded-full h-12 w-96 focus:outline-none ${
                  password && validity && `border-white`
                }
                ${validity ? `focus:border-white` : `focus:border-s2condPink`}`}
              />

              <div className="flex justify-between w-96 mx-auto">
                <p
                  className={`text-xs ${!password && `opacity-0 text-white`} ${
                    !validity && `text-s2condPink`
                  }`}
                >
                  {!validity && '소문자, 대문자, 특수문자, 숫자 포함 8자 이상'}
                </p>
                <button className="flex text-xs" onClick={onPassword}>
                  {passwordToggle ? '비밀번호 보기' : '비밀번호 숨기기'}
                  <span className="flex items-center mt-1 ml-1 focus:outline-none">
                    {/* <Emoji
                      emoji={passwordToggle ? 'grinning' : 'relieved'}
                      size={11}
                    /> */}
                  </span>
                </button>
              </div>
              <br />
            </div>
            <input
              type="submit"
              value={isSignin ? '로그인 하기' : '설정 완료'}
              className={`border border-textBlack bg-bgBlack text-center rounded-full h-12 w-96 mb-24 font-bold cursor-default focus:outline-none
              ${
                validity &&
                'border-2 hover:bg-s2condLime hover:text-black cursor-pointer'
              }
              ${validity && 'border-s2condLime text-s2condLime'}
              `}
            />
          </form>
        </div>
      </div>
    </div>
  )
}

export default SignEmail
