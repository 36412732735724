import React, {  useMemo } from 'react';
import { createPortal } from 'react-dom';

type Props = {
  elementId: string;
  children: any;
};
const Portal = ({ elementId, children }: Props) => {
  const rootElement = useMemo(
    () => document.getElementById(elementId),
    [elementId],
  ) as HTMLElement;

  return createPortal(children, rootElement);
};

export default Portal;