import React from 'react'
import { useNavigate } from 'react-router-dom'
import { UserStatus } from 'constants/userStatus'
import NavMainBtn from './NavMainBtn'

type Props = {
  userStatus: UserStatus
}
const Nav = ({ userStatus }: Props) => {
  const navigate = useNavigate()
  return (
    <div className="flex justify-between items-center h-12 bg-textBlack">
      <button
        className="md:ml-40 ml-56 text-white"
        onClick={() => navigate('/')}
      >
        Logo!
      </button>
      <NavMainBtn userStatus={userStatus} />
    </div>
  )
}

export default Nav
