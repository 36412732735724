export const setBorderColor = (status: string) => {
  if (status === 'lookingJob' || status === '구인중')
    return 'border-s2condPurple'
  if (status === 'preparingMove' || status === '이직 준비중')
    return 'border-s2condPink'
  if (status === 'proNjob' || status === '프로 N잡러')
    return 'border-s2condOrange'
  return 'border-borderGray'
}

export const setBgColor = (status: string) => {
  if (status === 'lookingJob' || status === '구인중') return 'bg-s2condPurple'
  if (status === 'preparingMove' || status === '이직 준비중')
    return 'bg-s2condPink'
  if (status === 'proNjob' || status === '프로 N잡러') return 'bg-s2condOrange'
  return 'bg-borderGray'
}

export const setTextColor = (status: string) => {
  if (status === 'lookingJob' || status === '구인중') return 'text-s2condPurple'
  if (status === 'preparingMove' || status === '이직 준비중')
    return 'text-s2condPink'
  if (status === 'proNjob' || status === '프로 N잡러')
    return 'text-s2condOrange'
  return 'text-borderGray'
}

export const jobVacanciesConv = (key: string | undefined) => {
  switch (key) {
    case 'lookingJob':
      return '구인중'
    case 'preparingMove':
      return '이직 준비중'
    case 'proNjob':
      return '프로 N잡러'
    default:
      return '해당 사항 없음'
  }
}

export const participationMethodConv = (key: string | undefined) => {
  switch (key) {
    case 'remote':
      return '원격 근무'
    case 'fullTime':
      return '풀타임'
    case 'partTime':
      return '파트타임'
    case 'selfCommuting':
      return '자율 출퇴근'
    default:
      return '상관없음'
  }
}
