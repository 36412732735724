import React, { useState } from 'react'
import Instagram from 'images/logos/instagram.png'
import LinkedIn from 'images/logos/linkedin.png'
import Github from 'images/logos/github.png'
import Notion from 'images/logos/notion.png'
import Youtube from 'images/logos/youtube.png'
import { profileContents } from 'types'
import SocialModal from '../Modal/SocialModal'

type Props = {
  s2condMe: Partial<profileContents>
  dailyMe: Partial<profileContents>
  menuState: string
  handleMe: (me: Partial<profileContents>) => void
}

const SocialBtnEdit: React.FC<Props> = ({
  s2condMe,
  dailyMe,
  menuState,
  handleMe,
}) => {
  const [onSocialModal, setOnSocialModal] = useState(false)
  const [socialType, setSocialType] = useState('instagram')
  const handleSocialModal = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setSocialType(e.currentTarget.name)
    setOnSocialModal((prev) => !prev)
  }

  //social 항목을 불러와서 비교
  return (
    <div className="flex">
      <button
        className="flex justify-center align-middle p-2 mr-2 border-1 rounded-full border-textBlack"
        onClick={(e) => handleSocialModal(e)}
        name="instagram"
      >
        <img src={Instagram} alt="instagram" />
      </button>
      <button
        className="flex justify-center align-middle p-2 mr-2 border-1 rounded-full border-textBlack"
        onClick={(e) => handleSocialModal(e)}
        name="linkedin"
      >
        <img src={LinkedIn} alt="linkedin" />
      </button>
      <button
        className="flex justify-center align-middle p-2 mr-2 border-1 rounded-full border-textBlack"
        onClick={(e) => handleSocialModal(e)}
        name="github"
      >
        <img src={Github} alt="github" />
      </button>
      <button
        className="flex justify-center align-middle p-2 mr-2 border-1 rounded-full border-textBlack"
        onClick={(e) => handleSocialModal(e)}
        name="notion"
      >
        <img src={Notion} alt="notion" />
      </button>
      <button
        className="flex justify-center align-middle p-2 mr-2 border-1 rounded-full border-textBlack"
        onClick={(e) => handleSocialModal(e)}
        name="youtube"
      >
        <img src={Youtube} alt="youtube" />
      </button>
      <SocialModal
        visibility={onSocialModal}
        setVisibility={setOnSocialModal}
        dataType={socialType}
        s2condMe={s2condMe}
        dailyMe={dailyMe}
        handleMe={handleMe}
        menuState={menuState}
      />
    </div>
  )
}

export default SocialBtnEdit
