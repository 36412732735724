import ai from 'images/emoji/ai.png'
import animation from 'images/emoji/animation.png'
import architecture from 'images/emoji/architecture.png'
import art from 'images/emoji/art.png'
import babyBoomer from 'images/emoji/babyBoomer.png'
import backhand from 'images/emoji/backhand.png'
import baseball from 'images/emoji/baseball.png'
import basketball from 'images/emoji/basketball.png'
import biology from 'images/emoji/biology.png'
import blockchain from 'images/emoji/blockchain.png'
import chillVibes from 'images/emoji/chillVibes.png'
import chinese from 'images/emoji/chinese.png'
import climate from 'images/emoji/climate.png'
import craft from 'images/emoji/craft.png'
import crystalball from 'images/emoji/crystalball.png'
import cycling from 'images/emoji/cycling.png'
import danceFun from 'images/emoji/danceFun.png'
import dating from 'images/emoji/dating.png'
import design from 'images/emoji/design.png'
import disabled from 'images/emoji/disabled.png'
import eCommerce from 'images/emoji/eCommerce.png'
import economics from 'images/emoji/economics.png'
import education from 'images/emoji/education.png'
import english from 'images/emoji/english.png'
import entertainment from 'images/emoji/entertainment.png'
import entrepreneurship from 'images/emoji/entrepreneurship.png'
import fashion from 'images/emoji/fashion.png'
import film from 'images/emoji/film.png'
import finance from 'images/emoji/finance.png'
import fire from 'images/emoji/fire.png'
import fitness from 'images/emoji/fitness.png'
import food from 'images/emoji/food.png'
import foodDrink from 'images/emoji/foodDrink.png'
import french from 'images/emoji/french.png'
import game from 'images/emoji/game.png'
import genX from 'images/emoji/genX.png'
import genZ from 'images/emoji/genZ.png'
import german from 'images/emoji/german.png'
import golf from 'images/emoji/golf.png'
import healthcare from 'images/emoji/healthcare.png'
import healthNutrition from 'images/emoji/healthNutrition.png'
import history from 'images/emoji/history.png'
import hr from 'images/emoji/hr.png'
import indonesian from 'images/emoji/indonesian.png'
import japanese from 'images/emoji/japanese.png'
import lgbtq from 'images/emoji/lgbtq.png'
import marketing from 'images/emoji/marketing.png'
import math from 'images/emoji/math.png'
import millennials from 'images/emoji/millennials.png'
import mobility from 'images/emoji/mobility.png'
import music from 'images/emoji/music.png'
import networking from 'images/emoji/networking.png'
import outdoor from 'images/emoji/outdoor.png'
import parenting from 'images/emoji/parenting.png'
import pet from 'images/emoji/pet.png'
import philosophy from 'images/emoji/philosophy.png'
import photography from 'images/emoji/photography.png'
import physics from 'images/emoji/physics.png'
import politics from 'images/emoji/politics.png'
import psychology from 'images/emoji/psychology.png'
import readingMovie from 'images/emoji/readingMovie.png'
import realEstate from 'images/emoji/realEstate.png'
import robotics from 'images/emoji/robotics.png'
import running from 'images/emoji/running.png'
import saas from 'images/emoji/saas.png'
import shopping from 'images/emoji/shopping.png'
import soccer from 'images/emoji/soccer.png'
import space from 'images/emoji/space.png'
import spanish from 'images/emoji/spanish.png'
import startup from 'images/emoji/startup.png'
import stock from 'images/emoji/stock.png'
import streaming from 'images/emoji/streaming.png'
import swim from 'images/emoji/swim.png'
import tennis from 'images/emoji/tennis.png'
import theater from 'images/emoji/theater.png'
import theFuture from 'images/emoji/theFuture.png'
import traveling from 'images/emoji/traveling.png'
import veganism from 'images/emoji/veganism.png'
import ventureAngel from 'images/emoji/ventureAngel.png'
import victoryHand from 'images/emoji/victoryHand.png'
import vrAr from 'images/emoji/vrAr.png'
import wedding from 'images/emoji/wedding.png'
import writing from 'images/emoji/writing.png'

type imgListType = {
  [key: string]: string
}
const interestImg: imgListType = {
  ai,
  animation,
  architecture,
  art,
  babyBoomer,
  backhand,
  baseball,
  basketball,
  biology,
  blockchain,
  chillVibes,
  chinese,
  climate,
  craft,
  crystalball,
  cycling,
  danceFun,
  dating,
  design,
  disabled,
  eCommerce,
  economics,
  education,
  english,
  entertainment,
  entrepreneurship,
  fashion,
  film,
  finance,
  fire,
  fitness,
  food,
  foodDrink,
  french,
  game,
  genX,
  genZ,
  german,
  golf,
  healthcare,
  healthNutrition,
  history,
  hr,
  indonesian,
  japanese,
  lgbtq,
  marketing,
  math,
  millennials,
  mobility,
  music,
  networking,
  outdoor,
  parenting,
  pet,
  philosophy,
  photography,
  physics,
  politics,
  psychology,
  readingMovie,
  realEstate,
  robotics,
  running,
  saas,
  shopping,
  soccer,
  space,
  spanish,
  startup,
  stock,
  streaming,
  swim,
  tennis,
  theater,
  theFuture,
  traveling,
  veganism,
  ventureAngel,
  victoryHand,
  vrAr,
  wedding,
  writing,
}
export default interestImg
