import React, { useEffect, useState } from 'react'

import Nav from 'components/common/Nav'
import { MEMBER } from 'constants/userStatus'
import { getUsers } from 'utils/firebase/Db'
import { DocumentData } from 'firebase/firestore'
import UserTable from 'components/Lounge/UserTable'
import { dbUserContents } from 'types'

const Lounge = () => {
  const [userList, setUserList] = useState<dbUserContents[]>([])

  useEffect(() => {
    const getAsyncUsers = async () => {
      const users = await getUsers()
      if (!users) return
      users.forEach((doc) => {
        // console.log(doc.data())
        setUserList((prev) => [...prev, doc.data() as dbUserContents])
      })
    }
    getAsyncUsers()
  }, [])
  return (
    <div className="w-full m-0 p-0 overflow-hidden bg-bgBlack">
      <Nav userStatus={MEMBER} />
      <div className="flex flex-wrap flex-row align-middle mt-24 w-4/5 mx-auto">
        {userList &&
          userList.map((user) => {
            return <UserTable key={user.uid} user={user} />
          })}
      </div>
    </div>
  )
}

export default Lounge
