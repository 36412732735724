import React from 'react'

import Footer from 'components/common/Footer'
import Router from 'Router'

function App() {
  return (
    <>
      <Router />
      <Footer />
    </>
  )
}

export default App
