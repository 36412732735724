export const jobVacanciesConv = (key: string | undefined) => {
  switch (key) {
    case 'lookingJob':
      return '구인중'
    case 'preparingMove':
      return '이직 준비중'
    case 'proNjob':
      return '프로 N잡러'
    default:
      return '해당 사항 없음'
  }
}
export const participationMethodConv = (key: string | undefined) => {
  switch (key) {
    case 'remote':
      return '원격 근무'
    case 'fullTime':
      return '풀타임'
    case 'partTime':
      return '파트타임'
    case 'selfCommuting':
      return '자율 출퇴근'
    default:
      return '상관없음'
  }
}

export const checkValid = (password: string) => {
  const reg =
    /^.*(?=^.{8,20}$)(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&+=]).*$/

  if (reg.test(password)) {
    return true
  } else {
    return false
  }
}
