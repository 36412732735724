import {
  GoogleAuthProvider,
  signInWithPopup,
  FacebookAuthProvider,
  getAuth,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendEmailVerification,
  onAuthStateChanged,
} from 'firebase/auth'

import { auth } from 'fbase'
import { globalUserContents } from 'types'
import { getUsernameByUid } from './Db'

export const googleSign = async () => {
  const googleProvider = new GoogleAuthProvider()
  try {
    const result = await signInWithPopup(auth, googleProvider)
    // const credential = GoogleAuthProvider.credentialFromResult(result)
    // if (!credential) return console.log('credential 없음')
    return result.user
  } catch (error) {
    console.log(error)
  }
}

export const facebookSign = async () => {
  const facebookProvider = new FacebookAuthProvider()
  try {
    const result = await signInWithPopup(auth, facebookProvider)
    // const credential = FacebookAuthProvider.credentialFromResult(result)
    return result.user
  } catch (error) {
    console.log(error)
  }
}

export const emailSignIn = async (email: string, password: string) => {
  const auth = getAuth()
  try {
    const result = await signInWithEmailAndPassword(auth, email, password)
    return result.user
  } catch (error) {
    console.log(error)
  }
}
export const emailSingUp = async (email: string, password: string) => {
  const auth = getAuth()
  try {
    const result = await createUserWithEmailAndPassword(auth, email, password)
    return result.user
  } catch (error) {
    console.log(error)
  }
}

export const verifyEmail = async () => {
  const actionCodeSettings = {
    url: 'https://s2cond.com/lounge',
    handleCodeInApp: true,
    // iOS: {
    //   bundleId: 'com.example.ios',
    // },
    // android: {
    //   packageName: 'com.example.android',
    //   installApp: true,
    //   minimumVersion: '12',
    // },
    // dynamicLinkDomain: 'example.page.link',
  }
  const auth = getAuth()
  if (!auth.currentUser) return console.log('err : 사용자가 없습니다.')
  try {
    const result = await sendEmailVerification(
      auth.currentUser,
      actionCodeSettings
    )
    return result
  } catch (error) {
    console.log(error)
  }
}

export const getAuthStateWithLoad = (
  changeUserState: (user: globalUserContents) => void
) => {
  onAuthStateChanged(auth, async (u) => {
    if (u) {
      const dbUsername = await getUsernameByUid(u.uid)
      changeUserState({
        uid: u.uid,
        email: u.email || '',
        emailVerified: u.emailVerified,
        phoneNumber: u.phoneNumber || '',
        photoURL: u.photoURL || '',
        displayName: u.displayName || '',
        username: dbUsername,
      })
    } else {
      console.log('user 없음')
    }
  })
}
