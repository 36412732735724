type emojiType = {
  [k: string]: string
}
export const emoji: emojiType = {
  ai: 'brain',
  animation: 'superhero',
  architecture: 'building_construction',
  art: 'art',
  babyBoomer: 'grinning',
  backhand: 'point_down',
  baseball: 'baseball',
  basketball: 'basketball',
  biology: 'dna',
  blockchain: 'moneybag',
  chillVibes: 'bathtub',
  chinese: 'cn',
  climate: 'earth_asia',
  craft: 'yarn',
  crystalball: 'crystal_ball',
  cycling: 'bicyclist',
  danceFun: 'dancer',
  dating: 'two_hearts',
  design: 'black_nib',
  disabled: 'person_in_manual_wheelchair',
  eCommerce: 'shopping_trolley',
  economics: 'chart_with_upwards_trend',
  education: 'mortar_board',
  english: 'us',
  entertainment: 'fairy',
  entrepreneurship: 'dollar',
  fashion: 'dress',
  film: 'movie_camera',
  finance: 'dollar',
  fire: 'fire',
  fitness: 'weight_lifter',
  food: 'hamburger',
  foodDrink: 'yum',
  french: 'fr',
  game: 'joystick',
  genX: 'relieved',
  genZ: 'stuck_out_tongue_winking_eye',
  german: 'de',
  golf: 'golf',
  healthcare: 'pill',
  healthNutrition: 'weight_lifter',
  history: 'moyai',
  hr: 'handshake',
  indonesian: 'flag-id',
  japanese: 'jp',
  lgbtq: 'rainbow',
  marketing: 'bar_chart',
  math: 'triangular_ruler',
  millennials: 'sunglasses',
  mobility: 'car',
  music: 'musical_note',
  networking: 'bust_in_silhouette',
  outdoor: 'camping',
  parenting: 'baby',
  pet: 'cat2',
  philosophy: 'thinking_face',
  photography: 'camera',
  physics: 'apple',
  politics: 'classical_building',
  psychology: 'thought_balloon',
  readingMovie: 'popcorn',
  realEstate: 'house_buildings',
  robotics: 'robot_face',
  running: 'runner',
  saas: 'cloud',
  shopping: 'shopping_bags',
  soccer: 'soccer',
  space: 'rocket',
  spanish: 'es',
  startup: 'unicorn_face',
  stock: 'ox',
  streaming: 'eyes',
  swim: 'swimmer',
  tennis: 'tennis',
  theater: 'performing_arts',
  theFuture: 'dizzy',
  traveling: 'airplane',
  veganism: 'green_salad',
  ventureAngel: 'seedling',
  victoryHand: 'v',
  vrAr: 'space_invader',
  wedding: 'ring',
  writing: 'writing_hand',
}
