import React from 'react'

import { profileContents } from 'types'
import Portal from './Portal'

type Props = {
  visibility: boolean
  setVisibility: React.Dispatch<React.SetStateAction<boolean>>
  dataType: string
  s2condMe: Partial<profileContents>
  dailyMe: Partial<profileContents>
  menuState: string
  handleMe: (me: Partial<profileContents>) => void
}

const SocialModal = ({
  visibility,
  setVisibility,
  dataType,
  s2condMe,
  dailyMe,
  menuState,
  handleMe,
}: Props) => {
  return (
    <Portal elementId="modal">
      <div
        className={`fixed top-0 left-0 w-full h-screen z-30 bg-black opacity-75 visible 
          ${!visibility && 'invisible'}`}
        onClick={() => setVisibility((prev) => !prev)}
      />
      <input
        type="text"
        placeholder="SNS 주소 입력"
        className={`
          fixed inset-1/2 -translate-x-1/2 -translate-y-1/2
          visible rounded-full w-1/2 z-40 px-8 py-6 bg-white opacity-75 text-2xl text-borderGray
          ${!visibility && 'invisible'}
          `}
      />
    </Portal>
  )
}

export default SocialModal
