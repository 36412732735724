import { interestsType } from 'constants/interests'
import { MENU_DAILY, MENU_S2COND, MENU_SETTING } from './constants'

export enum jobVacanciesType {
  lookingJob = '구인중',
  preparingMove = '이직 준비중',
  proNjob = '프로 N잡러',
  unselected = '해당 사항 없음',
}
export enum participationMethodType {
  remote = '원격 근무',
  fullTime = '풀타임',
  partTime = '파트타임',
  selfCommuting = '자율 출퇴근',
  unselected = '상관 없음',
}

export type EmailUser = {
  uid: string
  email: string
  emailVerified: boolean
  photoURL: string
  displayName: string
  interest: string[]
  dailyMe: profileContents
  s2condMe: profileContents
}

export type profileContents = {
  jobVacancies: jobVacanciesType
  participationMethod: participationMethodType
  displayName: string | undefined
  description: string | undefined
  picture: string | undefined
  emoji: string | undefined
  socialServices: string[]
  username: string
}

export type dbUserContents = {
  uid: string
  email: string
  emailVerified: boolean
  photoURL: string
  displayName: string
  interests: interestsType[]
  dailyMe: profileContents
  s2condMe: profileContents
  username: string
}

export type globalUserContents = {
  uid: string
  email: string
  emailVerified: boolean
  phoneNumber: string
  photoURL: string
  displayName: string
  username: string
}

export type userFieldForUpdate = {
  jobVacancies?: jobVacanciesType
  participationMethod?: participationMethodType
  displayName?: string
  description?: string
  picture?: string
  emoji?: string
  socialServices?: string[]
  username?: string
}
export type selectedInterestType = {
  [category: string]: string[]
}
export type MyProfileMenuType =
  | typeof MENU_DAILY
  | typeof MENU_S2COND
  | typeof MENU_SETTING

export type UserProfileMenuType = typeof MENU_DAILY | typeof MENU_S2COND
