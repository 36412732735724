import React from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'

import Nav from 'components/common/Nav'
import { LOGGING_IN, SIGNING_UP } from 'constants/userStatus'
import { facebookSign, googleSign } from 'utils/firebase/Auth'

import shinyEffect from 'images/emoji/shinyEffect.png'
import welcome from 'images/emoji/welcome.png'
import email from 'images/emoji/email.png'
import logoImg from 'images/logosAsset'
import signupGun from 'images/emoji/signupGun.png'
import useUserState from 'store/userState'
import { getUsernameByUid, isExistData, setUserDoc } from 'utils/firebase/Db'
import { selectedInterestType } from 'types'

type LocationState = {
  isSignin: boolean
  selectedInterest: selectedInterestType
}
const SignIn = () => {
  const { isSignin, selectedInterest } = useLocation().state as LocationState
  const [_, setUserState] = useUserState()
  const navigate = useNavigate()
  const onSocialClick = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    const name = event.currentTarget.getAttribute('name')
    const signResult =
      name === 'google' ? await googleSign() : await facebookSign()
    if (!signResult) return 'err'
    const dbUsername = await getUsernameByUid(signResult.uid)

    setUserState({
      uid: signResult.uid,
      email: signResult.email || '',
      emailVerified: signResult.emailVerified,
      phoneNumber: signResult.phoneNumber || '',
      photoURL: signResult.photoURL || '',
      displayName: signResult.displayName || '',
      username: dbUsername,
    })
    if (!(await isExistData(signResult.uid))) {
      setUserDoc(signResult, selectedInterest)
    }
    !dbUsername ? navigate('/init-username') : navigate('/lounge')
  }
  const emailSign = () => {
    navigate('/email', { state: { isSignin, selectedInterest } })
  }
  return (
    <div className="w-full m-0 p-0 overflow-hidden bg-bgBlack">
      <Nav userStatus={isSignin ? LOGGING_IN : SIGNING_UP} />
      {isSignin ? (
        <div className="text-center text-2xl text-s2condLime mt-36 mb-24">
          <img src={welcome} alt="welcome" className="h-20 mx-auto" />
          <div className="flex justify-center  mx-auto">
            <p className="mt-5">반갑습니다, 요원님!</p>
            <img src={shinyEffect} alt="shiny-effect" className="h-8" />
          </div>

          <p className="font-bold ">~~~~~신분을 밝혀주세요~~~~~</p>
        </div>
      ) : (
        <div className="text-center text-s2condLime mt-36 mb-24">
          <img
            src={signupGun}
            alt="signup-gun"
            className="w-20 h-auto mx-auto"
          />
          <p className="text-lg font-bold">
            회원가입 할 계정 혹은 이메일을 선택 해 주세요
          </p>
          {/* <p className="font-thin">
          s2cond에 가입 하시려면 이미 가입한 지인의 초대가 필요 합니다.
          <br />
          아직 초대장이 없으신 분은 요원 신청을 통해{' '}
          <b>
            <b>Waitlist</b>
          </b>
          에 등록 하실 수 있습니다.
        </p> */}
        </div>
      )}
      <div className="flex justify-center mb-48">
        <div>
          <button
            className="flex items-center w-72 border border-borderGray rounded-full px-6 py-4 mb-8 text-sm text-white font-bold hover:border-s2condLime"
            onClick={onSocialClick}
            name="google"
          >
            <img src={logoImg.google} alt="google Signin" className="w-6" />
            <p className="mx-auto text-center">
              {isSignin ? 'Sign in with Google' : 'Sign up with Google'}
            </p>
          </button>
          <button
            className="flex items-center w-72 border border-borderGray rounded-full px-6 py-4 mb-8 text-sm text-white font-bold hover:border-s2condLime"
            onClick={onSocialClick}
            name="facebook"
          >
            <img src={logoImg.facebook} alt="facebook Signin" className="w-6" />
            <p className="mx-auto text-center">
              {isSignin ? 'Sign in with Facebook' : 'Sign up with Facebook'}
            </p>
          </button>
          <button
            className="flex items-center w-72 border border-borderGray rounded-full px-6 py-4 mb-8 text-sm text-white font-bold hover:border-s2condLime"
            onClick={emailSign}
          >
            <img src={email} alt="email signup" className="w-6" />
            <p className="mx-auto text-center">
              {isSignin ? 'Sign in with Email' : 'Sign up with Email'}
            </p>
          </button>
          <Link to="/findAccount" className="flex justify-center">
            <p className="inline-flex text-sm text-white border-b border-bgBlack hover:text-s2condLime hover:border-b hover:border-s2condLime">
              Find Email / Password
            </p>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default SignIn
