import { atom, useRecoilState } from 'recoil'
import { globalUserContents } from 'types'

const userStore = atom({
  key: '@user',
  default: {
    uid: '',
    email: '',
    emailVerified: false,
    phoneNumber: '',
    photoURL: '',
    displayName: '',
    username: '',
  },
})

const useUserState = () => useRecoilState<globalUserContents>(userStore)
export default useUserState
