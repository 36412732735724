import {
  jobVacanciesType,
  participationMethodType,
  profileContents,
} from 'types'

export const emptyProfile: profileContents = {
  jobVacancies: jobVacanciesType.unselected,
  participationMethod: participationMethodType.unselected,
  displayName: '',
  description: '',
  picture: '',
  emoji: '',
  socialServices: [],
  username: '',
}
